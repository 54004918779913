import React, { useState } from "react";
import {
  Grid,
  Avatar,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  TextField,
  Select,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import API from "../../utility/axiosClient";
import { Helmet } from "react-helmet";
import EnvConfig from "../../config/config";
import { uniqBy } from "lodash";
import moment from "moment";
import { Done, Lock, MoreHoriz } from "@material-ui/icons/";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const statusStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "28px",
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    position: "relative",
    float: "right",
  },
});

class allProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusStages: [],
      selectedOrganization: null,
      updateStages: [],
    };
  }
  componentDidMount() {
    this.getStatus();
  }
  getStatus = async () => {
    const {
      data: { results },
    } = await API.get(`/api/admin/projects/`);
    console.log(results);
    this.setState({
      statusStages: results,
    });
  };
  handleSelectOrganization = (optionSelected) => {
    this.setState({
      selectedOrganization: optionSelected,
    });
    console.log(optionSelected);
  };
  render() {
    const { classes } = this.props;
    const { selectedOrganization } = this.state;
    let { statusStages } = this.state;
    const organizations = uniqBy(statusStages.map(({ org_id }) => org_id));

    if (selectedOrganization)
      statusStages = statusStages.filter(
        ({ org_id }) => org_id === selectedOrganization
      );
    const appHeader = `${EnvConfig.getConfig().appHeader}  Reports`;
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{appHeader}</title>
        </Helmet>
        <Grid
          container
          spacing={0}
          style={{ marginBottom: "14px", minHeight: "140px" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>
              All Projects POC Status Tracker
            </Typography>
            <FormControl
              style={{
                minWidth: "170px",
                position: "relative",
                float: "right",
                bottom: "55px",
              }}
            >
              <InputLabel id="select-organization">
                Select Organization
              </InputLabel>
              <Select
                id="organization-id-label"
                style={{
                  width: "260px",
                  height: "30px",
                }}
                onChange={(e) => this.handleSelectOrganization(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Select Organization
                </MenuItem>
                <MenuItem key={"all"} value={null}>
                  All
                </MenuItem>
                {organizations.map((organization) => {
                  return (
                    <MenuItem key={organization} value={organization}>
                      {organization}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {statusStages.map((project, index) => (
            <ProjectStatus
              project={project}
              key={index}
              reloadProgress={() => this.getStatus()}
            />
          ))}
        </Grid>
      </div>
    );
  }
}

const ProjectStatus = ({ project, reloadProgress }) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ marginBottom: "20px", fontSize: 15, fontWeight: "600" }}
        >
          {project.title}
        </Grid>

        {project.poc_project_status_stages.map((status, idx) => {
          const isCurrentlyInProgress = status?.status === "in progress";
          return (
            <ProcessStatus
              key={idx}
              status={status}
              reloadProgress={reloadProgress}
              isCurrentlyInProgress={isCurrentlyInProgress}
            />
          );
        })}
      </Grid>
    </>
  );
};

const ProcessStatus = ({
  key,
  status,
  isCurrentlyInProgress,
  reloadProgress,
}) => {
  const isCompleted = status.status === "complete";
  const [expand, toggleExpand] = useState(false);
  const [isStatus, setStatus] = useState(status.status);
  const [selectedDate, setSelectedDate] = useState(
    isCompleted
      ? new Date(status?.completed_datetime.replace(/-/g, "/"))
      : new Date()
  );

  const updateStatus = async () => {
    let value = {
      create_or_update: [
        {
          id: status.id,
          // completed: isChecked,
          status: isStatus,
          completed_datetime: moment(selectedDate).format(
            "MM-DD-YYYY HH:mm:ss"
          ),
        },
      ],
      delete: [],
    };

    await API.patch(`/api/admin/status-stages/`, value);
    reloadProgress();
    toggleExpand(false);
  };

  const MyDatePicker = ({ value, onClick }) => (
    <React.Fragment>
      <TextField
        color="primary"
        style={{ width: "220px" }}
        defaultValue={value}
        onClick={onClick}
      />
      <CalendarTodayIcon
        onClick={onClick}
        style={{
          zIndex: "999",
          marginLeft: "-25px",
          fontSize: "20px",
          cursor: "pointer",
          color: "#000000",
          marginTop: "7px",
        }}
      ></CalendarTodayIcon>
    </React.Fragment>
  );

  return (
    <>
      <Grid
        container
        item
        xs={12}
        key={key}
        style={{
          borderTop: isCompleted ? "1px solid #332d3c" : "0px",
          paddingTop: "5px",
          paddingBottom: "5px",
          cursor: "pointer",
          backgroundColor: isCurrentlyInProgress
            ? "#332d3c"
            : isCompleted
            ? "#ffffff"
            : "#f7f7f7",
          color: isCurrentlyInProgress ? "#ffffff" : "#808080",
          marginBottom: "14px",
        }}
        onClick={() => toggleExpand(!expand)}
      >
        <Grid
          item
          xs={1}
          style={{
            textAlign: "center",
            marginTop: "5px",
            marginLeft: "5px",
            paddingRight: "15px",
            marginRight: "-28px",
          }}
        >
          <Avatar
            style={{
              width: "28px",
              height: "28px",
              margin: "auto",
              backgroundColor: isCurrentlyInProgress
                ? "#231f29"
                : isCompleted
                ? "#34cb81"
                : "#f0f0f0",
            }}
          >
            {isCompleted ? (
              <Done style={{ width: "25px", height: "25px" }} />
            ) : isCurrentlyInProgress ? (
              <MoreHoriz
                style={{
                  color: "white",
                }}
              />
            ) : (
              <Lock
                style={{
                  width: "16px",
                  height: "16px",
                  color: "#696969",
                }}
              />
            )}
          </Avatar>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            color: isCurrentlyInProgress
              ? "#ffffff"
              : isCompleted
              ? "#000000"
              : "#7c7c7c",
            fontWeight: "600",
            marginTop: "8px",
            lineHeight: "22px",
            fontSize: "14px",
            width: "176px",
            height: "30px",
          }}
        >
          {status.title}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            textAlign: "right",
            paddingRight: "10px",
            fontWeight: "600",
            lineHeight: "22px",
            fontSize: "14px",
            marginTop: "8px",
            width: "176px",
            height: "30px",
          }}
        >
          {isCompleted
            ? moment(
                new Date((status?.completed_datetime).replace(/-/g, "/"))
              ).format("MM/DD HH:mm")
            : isCurrentlyInProgress
            ? "In Progress"
            : ""}
        </Grid>
      </Grid>
      {expand && (
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          xs={12}
          style={{
            position: "relative",
            bottom: "8px",
          }}
        >
          <Grid item xs={3}>
            <FormControl style={{ minWidth: "150px", position: "relative" }}>
              <Select
                id="status_stages_id"
                style={{
                  width: "120px",
                  height: "30px",
                }}
                value={isStatus}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={"complete"}>Completed</MenuItem>
                <MenuItem value={"in progress"}>In Progress</MenuItem>
                <MenuItem value={"inactive"}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              color: isCurrentlyInProgress
                ? "#ffffff"
                : isCompleted
                ? "#000000"
                : "red",
            }}
          >
            <DatePicker
              showTimeSelect
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MM/dd/yyyy h:mm aa"
              timeFormat="HH:mm aa"
              timeIntervals={1}
              timeCaption="Time"
              customInput={<MyDatePicker />}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Submit">
              <Button
                variant="contained"
                style={{
                  fontSize: "14px",
                  textTransform: "none",
                  color: "#000000",
                }}
                onClick={() => updateStatus()}
              >
                Submit
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withStyles(statusStyles)(allProjects);
