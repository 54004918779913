import React from "react";
import { Grid, InputLabel, FormControl } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import API from "../../utility/axiosClient";
import { Helmet } from "react-helmet";
import EnvConfig from "../../config/config";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Axios from "axios";
import { uniqBy } from "lodash";
import cookies from "../../utility/cookies";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Delete } from "@material-ui/icons";
import { ConfirmContainer } from "../../common/ConfirmContainer";

const fileStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "28px",
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
  },
  table: {
    overflow: "hidden",
    width: "95%",
    margin: "1.5% 2.5%",
    overflowX: "auto",
    backgroundColor: "#FFF",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  tableCell: {
    fontSize: 16,
    fontWeight: 600,
    color: "#fff",
  },
  textField: {
    width: "300px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "170px",
    position: "relative",
    float: "right",
    bottom: "65px",
  },
  menuItem: {
    height: "22px",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "normal",
    color: "#000000",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class AllFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      entityTypes: [],
      mappings: [],
      selectedFileType: "",
      rowsPerPage: 10,
      addFile: false,
      error: true,
      count: 0,
      page: 0,
      showFilter: false,
      selectedFilterFileEntityType: null,
      file: null,
      fileId: null,
      selectedOrganization: null,
    };
    this.onDeleteFile = this.onDeleteFile.bind(this);
  }

  async onDeleteFile(action) {
    const { file } = this.state;
    try {
      if (action) {
        await API.delete(`api/customer-files/${file.id}/`);
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ file: null }, this.getFiles);
  }
  componentDidMount() {
    this.getFiles();
  }
  getFiles = async () => {
    this.setState({ files: [] });
    const {
      data: { results, count },
    } = await API.get(`/api/admin/customer-files/`);
    this.setState({
      files: results,
      count: count,
    });
  };

  handleSelectOrganization = (optionSelected) => {
    this.setState({
      selectedOrganization: optionSelected,
    });
    console.log(optionSelected);
  };
  render() {
    const { classes } = this.props;
    const { rowsPerPage, page, count, selectedOrganization, file } = this.state;
    let { files } = this.state;
    const organizations = uniqBy(
      files.map(({ organization_id }) => organization_id)
    );
    if (selectedOrganization)
      files = files.filter(
        ({ organization_id }) => organization_id === selectedOrganization
      );
    const appHeader = `${EnvConfig.getConfig().appHeader}  Reports`;
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{appHeader}</title>
        </Helmet>
        <Grid
          container
          spacing={0}
          style={{ marginBottom: "14px", minHeight: "140px" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>All Files</Typography>
            <FormControl className={classes.formControl}>
              <InputLabel id="organization-select">
                Select Organization
              </InputLabel>
              <Select
                id="organization-id-label"
                className={classes.selectEmpty}
                style={{
                  width: "260px",
                  height: "30px",
                }}
                onChange={(e) => this.handleSelectOrganization(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Select Organization
                </MenuItem>
                <MenuItem key={"all"} value={null}>
                  All
                </MenuItem>
                {organizations.map((organization) => {
                  return (
                    <MenuItem key={organization} value={organization}>
                      {organization}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FileManagementGrid
            files={files}
            history={this.props.history}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={this.setPage}
            deleteFile={(file) => this.setState({ file })}
            setRowsPerPage={this.setRowsPerPage}
          />
          {file && (
            <ConfirmContainer
              open={true}
              title={"Delete?"}
              description={`would you like to delete file "${file.file_name}"?`}
              confirmTxt={"OK"}
              onAction={this.onDeleteFile}
            />
          )}
        </Grid>
      </div>
    );
  }
}

const FileManagementGrid = ({ files, deleteFile }) => {
  const downloadFile = async (file) => {
    Axios.defaults.headers.common = {
      Authorization: "Bearer " + cookies.get("token"),
    };
    Axios({
      url:
        EnvConfig.getConfig().apiUrl + `/api/admin/download-file/${file.id}/`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${file.file_name.replace(/\.[^.]*$/, "")}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((er) => er);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead style={{ backgroundColor: "#332D3C" }}>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Organization
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                File
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                File Type
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Description
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Created Date
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <TableRow key={file.id}>
                <TableCell align="center">{file.organization_id}</TableCell>
                <TableCell align="center">{file.file_name}</TableCell>
                <TableCell align="center">
                  {file.customer_file_type?.file_type}
                </TableCell>
                <TableCell align="center">
                  {file?.customer_file_type?.file_type === "other"
                    ? file?.other_description
                    : file?.customer_file_type?.description}
                </TableCell>
                <TableCell align="center">{file.created_at}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() => downloadFile(file)}
                      style={{
                        minWidth: "26px",
                        padding: "7px",
                        marginRight: 10,
                      }}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => deleteFile(file)}
                      style={{
                        minWidth: "26px",
                        padding: "7px",
                        marginRight: 10,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(fileStyles)(AllFiles);
