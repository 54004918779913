import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Grid, Button, Typography, Avatar } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons/";

const useStyles = makeStyles(() => ({
  backBtn: {
    cursor: "pointer",
    width: "160px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  backBtnTxt: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  backGrdImg: {
    backgroundImage: "url('assets/images/circle-graphics.png')",
    backgroundSize: "cover",
    height: "calc(100vh - 56px)",
    backgroundPosition: "30% 30% ",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
}));

export default function Message(props) {
  const classes = useStyles();
  const location = window.location;
  const key = new URLSearchParams(
    location.href
      .substring(location.href.lastIndexOf("?"), location.href.length)
      .slice(1)
  ).get("status");

  if (key) {
    var params = new URLSearchParams(
      location.href
        .substring(location.href.lastIndexOf("?"), location.href.length)
        .slice(1)
    );
    params.delete(key);
    var url = location.href.substring(0, location.href.lastIndexOf("?"));
    window.history.replaceState({}, "", url);
  }

  function onBack() {
    props.history.push("/FileUpload");
  }

  return (
    <div>
      <Grid container className={classes.backGrdImg}>
        <Typography style={{ marginTop: "15px" }}>
          <Grid container className={classes.backBtn}>
            <Grid item xs={2} spacing={1}>
              <Avatar
                style={{
                  backgroundColor: "#f0f0f0",
                  width: "36px",
                  height: "36px",
                }}
              >
                <NavigateBeforeIcon
                  onClick={onBack}
                  style={{
                    color: "#000000",
                  }}
                />
              </Avatar>
            </Grid>
            <Grid item xs={10} justify="flex-end" alignItems="center">
              <Button
                style={{
                  color: "#000000",
                  width: "105%",
                  textTransform: "none",
                }}
                className={classes.backBtnTxt}
                onClick={onBack}
              >
                <b>Back to Uploads</b>
              </Button>
            </Grid>
          </Grid>
        </Typography>

        <Grid
          container
          direction="row"
          display="flex"
          justify="center"
          alignItems="center"
          style={{ height: "60vh" }}
        >
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "35px",
              }}
            >
              <Avatar
                style={{
                  width: "80px",
                  height: "80px",
                  backgroundColor: "#f7f7f7",
                }}
              >
                <CheckCircle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#34cb81",
                    height: "48px",
                    width: "48px",
                  }}
                />
              </Avatar>
            </Typography>
            <Typography
              style={{
                fontSize: "40px",
                lineHeight: "48px",
                textAlign: "center",
                color: "#000000",
              }}
            >
              <b> Thank you! Your data has been successfully uploaded </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
