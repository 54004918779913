import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import DefaultDashboard from "./components/dashboard/default_dashboard";
import SignIn from "./components/Authentication/logIn";
import history from "./history";
import PrivateRoute from "./components/Authentication/privateRoute";
import FileUpload from "./components/uploader/Upload";
import store from "./redux/store";
import { Provider } from "react-redux";
import FilesView from "./components/files/files";
import AllFiles from "./components/files/allFiles";
import StatusView from "./components/files/Status";
import AllProjects from "./components/files/allProjects";
import FileUploadSuccess from "./components/uploader/Message";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
  },
});

const styles = () => ({});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>POC-UI</title>
        </Helmet>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={SignIn} />
            <PrivateRoute
              exact
              path="/(|FileUpload)/"
              layout={DefaultDashboard}
              component={FileUpload}
            />
            <PrivateRoute
              exact
              path="/(|FileUpload)/Success"
              layout={DefaultDashboard}
              component={FileUploadSuccess}
            />
            <PrivateRoute
              exact
              path="/files"
              layout={DefaultDashboard}
              component={FilesView}
            />
            <PrivateRoute
              exact
              path="/allFiles"
              layout={DefaultDashboard}
              component={AllFiles}
            />
            <PrivateRoute
              exact
              path="/status"
              layout={DefaultDashboard}
              component={StatusView}
            />
            <PrivateRoute
              exact
              path="/allProjects"
              layout={DefaultDashboard}
              component={AllProjects}
            />
          </Switch>
        </Router>
      </div>
    </Provider>
  </MuiThemeProvider>
);

export default withStyles(styles, { withTheme: true })(App);
