import React from "react";
import { Redirect } from "react-router-dom";
import {
  Link,
  AppBar,
  Toolbar,
  CssBaseline,
  Button,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core/";
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ArrowForward } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { withAuth0 } from "@auth0/auth0-react";
import EnvConfig from "../../config/config";
const styles = (theme) => ({
  paper: {
    alignItems: "center",
    padding: `${theme.spacing(7)}px ${theme.spacing(7)}px ${theme.spacing(
      4
    )}px`,
    boxShadow: "none",
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  btnSignIn: {
    marginTop: theme.spacing(5),
    backgroundColor: "#312e3a",
    color: "#fff",
    justifyContent: "inherit",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
  signInSection: {
    height: "100vh",
    backgroundColor: "white",
  },
  help: {
    display: "box",
    boxPack: "center",
    boxAlign: "center",
    textAlign: "right",
  },
  bannerImage: {
    backgroundImage: "url('assets/images/signin-background.png')",
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  labelIcon: {
    fontSize: "1.2rem",
  },
  branding: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing.unit,
  },
  footer: {
    bottom: theme.spacing(5),
    padding: `${theme.spacing(5)}px ${theme.spacing(7)}px ${theme.spacing(
      4
    )}px`,
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    transform: "none",
    borderBottom: "1px solid #000000",
  },
  validationError: { color: "red", fontSize: "11px" },
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
    fontWeight: 600,
  },
});

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      userNameError: "",
      passwordError: "",
    };
  }
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
    this.setState({ [event.target.name + "Error"]: "" });
    //this.props.SignInClear();
    if (event.key === "Enter") {
      this.getToken(event);
    }
  };

  getToken = (event) => {
    event.preventDefault();
    if (this.isValidState()) {
      //this.props.SignIn(this.state.userName, this.state.password);
    }
  };

  isValidState() {
    let isValid = true;
    if (!this.state.userName) {
      this.setState({ userNameError: "Username is required." });
      isValid = false;
    }
    if (!this.state.password) {
      this.setState({ passwordError: "Password is required." });
      isValid = false;
    }
    return isValid;
  }

  componentDidMount() {
    // this.props.SignOut();
    const {
      location: { hash },
    } = this.props;
    if (hash.includes("expired")) {
      //this.props.onExpiry();
      window.location.hash = "";
    }
  }

  componentWillReceiveProps(nextProps) {
    const { type, history } = nextProps;
    const path = localStorage.getItem("path")
      ? localStorage.getItem("path")
      : "/reports";
    type === "SIGNIN_SUCCESS" && history.push(path);
  }

  render() {
    const {
      classes,
      auth0: { isAuthenticated, isLoading, loginWithRedirect },
    } = this.props;
    if (isLoading) return null;
    if (isAuthenticated) {
      const path = localStorage.getItem("path")
        ? localStorage.getItem("path")
        : "/reports";

      return <Redirect to={path} />;
    }

    const appHeader = `${EnvConfig.getConfig().appHeader} Sign In`;

    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{appHeader}</title>
          </Helmet>
          <CssBaseline />
          <Grid container>
            <Grid item md={4} className={classes.signInSection}>
              <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.branding}>
                    <img
                      alt="logo"
                      src="assets/images/OptimalDynamics-logo.svg"
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
              <Paper className={classes.paper}>
                <Typography
                  component="h1"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                >
                  Sign In
                </Typography>
                <form className={classes.form}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ width: "100%", justifyContent: "space-between" }}
                    onClick={loginWithRedirect}
                    endIcon={<ArrowForward />}
                    className={classes.btnSignIn}
                  >
                    Sign In
                  </Button>
                </form>
              </Paper>
              <footer className={classes.footer}>
                <b>Warning:</b> Use of this System is Restricted to Authorized
                Users. <Link href="#">Read More</Link>
              </footer>
            </Grid>
            <Grid item md={8} className={classes.bannerImage}></Grid>
          </Grid>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withAuth0(LogIn));
