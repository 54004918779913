import React from "react";
import { Button, Avatar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { withAuth0 } from "@auth0/auth0-react";
import cookies from "../../utility/cookies";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import API from "../../utility/axiosClient";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  paperStyle: {
    backgroundColor: "#ffffff",
    minHeight: "calc(100vh - 65px)",
    boxShadow: "none !important",
  },
  navButton: {
    color: "#fff",
    cursor: "pointer",
    border: "1px #312e3a",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#47434f",
    },
    "& > *": {
      padding: "8px 8px",
    },
    borderRadius: "0px",
  },
  navButtonSelected: {
    color: "#fff",
    cursor: "pointer",
    border: "1px #312E3A",
    textAlign: "center",
    backgroundColor: "#231f29",
    "& > *": {
      padding: "8px 8px",
    },
    "&:hover": {
      backgroundColor: "#47434f",
    },
    borderRadius: "0px",
  },
  navBar: {
    backgroundColor: "#332d3c",
    minHeight: "56px",
    height: "56px",
  },
  appLogo: { height: "25px" },
});

class DefaultDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      roles: [],
      token: null,
      mobileMoreAnchorEl: null,
      fileErrorLogs: [],
      notificationsAnchorEl: null,
      pathname:
        window.location.pathname === "/"
          ? "/FileUpload"
          : window.location.pathname,
    };
    this.logoutAuth = this.logoutAuth.bind(this);
  }
  componentWillMount() {
    this.unlisten = this.props.children.props.history.listen((location) => {
      this.setState({ pathname: location.pathname });
      if (!location.pathname.includes("login"))
        localStorage.setItem("path", location.pathname);
    });
  }

  componentDidMount() {
    const { token } = this.state;
    !token && this.getToken(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { token } = this.state;
    !token && this.getToken(nextProps);
  }

  async getToken(props) {
    const {
      history,
      auth0: { isAuthenticated, isLoading, getAccessTokenSilently },
    } = props;
    if (isLoading) return;
    if (!isAuthenticated) history.push(`/sign-in`);
    const token = await getAccessTokenSilently();
    cookies.set("token", token);

    const {
      data: { roles },
    } = await API.get(`/api/user-info/`);

    this.setState(
      {
        token,
        roles,
      },
      () => {
        const { pathname, roles } = this.state;
        if (
          pathname === "/allFiles" ||
          (pathname === "/allProjects" && !roles.includes("super-admin"))
        )
          this.props.children.props.history.push("/files");
      }
    );
  }

  logoutAuth() {
    const {
      auth0: { logout },
    } = this.props;
    logout({ returnTo: window.location.origin });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { classes } = this.props;
    const { token, pathname, roles } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="relative" color="default">
          <Toolbar className={classes.navBar}>
            <div>
              <img
                src={"/assets/images/logo.svg"}
                alt="Optimal Dynamics logo"
                className={classes.appLogo}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Tooltip title="Upload">
                <Button
                  className={
                    pathname.includes("/FileUpload") &&
                    pathname.indexOf("/FileUpload") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/FileUpload"
                >
                  Upload
                </Button>
              </Tooltip>
              <Tooltip title="Files">
                <Button
                  className={
                    pathname.includes("/files") &&
                    pathname.indexOf("/files") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/files"
                >
                  Files
                </Button>
              </Tooltip>

              {roles.includes("super-admin") && (
                <Tooltip title="All Files">
                  <Button
                    className={
                      pathname.includes("/allFiles") &&
                      pathname.indexOf("/allFiles") === 0
                        ? classes.navButtonSelected
                        : classes.navButton
                    }
                    component={Link}
                    to="/allFiles"
                  >
                    All Files
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Status">
                <Button
                  className={
                    pathname.includes("/status") &&
                    pathname.indexOf("/status") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/status"
                >
                  Status
                </Button>
              </Tooltip>
              {roles.includes("super-admin") && (
                <Tooltip title="All Projects">
                  <Button
                    className={
                      pathname.includes("/allProjects") &&
                      pathname.indexOf("/allProjects") === 0
                        ? classes.navButtonSelected
                        : classes.navButton
                    }
                    component={Link}
                    to="/allProjects"
                  >
                    All Projects
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Logout">
                <Button component={Link} to="#" onClick={this.logoutAuth}>
                  <Avatar style={{ backgroundColor: "#231f29" }}>
                    <ExitIcon />
                  </Avatar>
                </Button>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Paper square={true} className={classes.paperStyle}>
          {token && this.props.children}
        </Paper>
      </div>
    );
  }
}

DefaultDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withAuth0(DefaultDashboard)
);
