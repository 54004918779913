import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import API from "../../utility/axiosClient";
import { Helmet } from "react-helmet";
import EnvConfig from "../../config/config";
import { Done, Lock, MoreHoriz } from "@material-ui/icons/";
import moment from "moment";

const statusStyles = () => ({
  root: {
    flexGrow: 1,
    padding: "28px",
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
  },
});

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusStages: [],
    };
  }
  componentDidMount() {
    this.getStatus();
  }
  getStatus = async () => {
    const {
      data: { results },
    } = await API.get(`/api/projects/`);
    this.setState({
      statusStages: results,
    });
  };
  render() {
    const { classes } = this.props;
    const appHeader = `${EnvConfig.getConfig().appHeader}  Reports`;
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{appHeader}</title>
        </Helmet>
        <Grid
          container
          spacing={0}
          style={{ marginBottom: "14px", minHeight: "140px" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>
              POC Status Tracker
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {this.state.statusStages.map((project) => (
            <ProjectStatus project={project} />
          ))}
        </Grid>
      </div>
    );
  }
}

const ProjectStatus = ({ project }) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ marginBottom: "20px", fontSize: 15, fontWeight: "600" }}
        >
          {project.title}
        </Grid>
        {project.poc_project_status_stages.map((status, idx) => {
          const isCurrentlyInProgress = status?.status === "in progress";
          return (
            <Grid
              container
              item
              xs={12}
              key={idx}
              style={{
                borderTop:
                  status.status === "complete" ? "1px solid #332d3c" : "0px",
                paddingTop: "5px",
                paddingBottom: "5px",
                cursor: "pointer",
                backgroundColor: isCurrentlyInProgress
                  ? "#332d3c"
                  : status.status === "complete"
                  ? "#ffffff"
                  : "#f7f7f7",
                color: isCurrentlyInProgress ? "#ffffff" : "#808080",
                marginBottom: "14px",
              }}
            >
              <Grid
                item
                xs={1}
                style={{
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "8px",
                  paddingLeft: "8px",
                  paddingRight: "28px",
                }}
              >
                <Avatar
                  style={{
                    width: "28px",
                    height: "28px",
                    margin: "auto",
                    backgroundColor: isCurrentlyInProgress
                      ? "#231f29"
                      : status.status === "complete"
                      ? "#34cb81"
                      : "#f0f0f0",
                  }}
                >
                  {status.status === "complete" ? (
                    <Done style={{ width: "25px", height: "25px" }} />
                  ) : isCurrentlyInProgress ? (
                    <MoreHoriz
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    <Lock
                      style={{
                        width: "16px",
                        height: "16px",
                        color: "#696969",
                      }}
                    />
                  )}
                </Avatar>
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  color: isCurrentlyInProgress
                    ? "#FFF"
                    : status.status === "complete"
                    ? "#000000"
                    : "#7c7c7c",
                  fontWeight: "600",
                  marginTop: "8px",
                  right: "28px",
                  position: "relative",
                  lineHeight: "22px",
                  fontSize: "14px",
                  width: "176px",
                  height: "30px",
                }}
              >
                {status.title}
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  textAlign: "right",
                  paddingRight: "28px",
                  fontWeight: "600",
                  lineHeight: "22px",
                  fontSize: "14px",
                  marginTop: "5px",
                  width: "176px",
                  height: "22px",
                }}
              >
                {status.status === "complete"
                  ? moment(status.completed_datetime).format("MM/DD HH:mm")
                  : isCurrentlyInProgress
                  ? "In Progress"
                  : ""}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default withStyles(statusStyles)(Status);
