import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import React, { Component } from "react";
import cookies from "../../utility/cookies";
import EnvConfig from "../../config/config";
import LinearWithValueLabel from "../progressBar/customLoader";
import Dropzone from "./Dropzone";
import Mapping from "./selectMapping";
import "./Upload.css";
import UploadFileHeader from "./uploadFileHeader";
import { Button } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { GetCustomerFileType } from "../../redux/actions";
import { Redirect } from "react-router-dom";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingLeft: "25px",
    paddingRight: "25px",
    alignItems: "flex-start",
    textAlign: "center",
    overflow: "hidden",
  },
  btnUpload: {
    marginTop: theme.spacing(5),
    backgroundColor: "#312e3a",
    color: "#fff",
    justifyContent: "inherit",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
});

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileObjects: [],
      maxFilesAllowed: 10,
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      fileType: this.props.entityType,
      entityType: {},
      disableUpload: false,
      fileId: null,
      reportId: this.props.reportId,
      reportData: { report_type: {} },
      mappings: [],
      selectedFileType: 0,
      previouslySelected: this.props.selected,
      progress: 0,
      showProgresBar: false,
      isFileUplodSuccess: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.resetUploader = this.resetUploader.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  async componentDidMount() {
    await this.props.getCustomerFileTypes();
  }

  onFilesAdded(files) {
    let fileObjects = this.state.fileObjects;
    let hasFileExtensionErrors = false;
    let fileCount = 0;
    files.forEach((file) => {
      if (fileCount < this.state.maxFilesAllowed) {
        let fileObject = {};
        fileObject["file"] = file;
        fileObject["file_type"] = 0;
        if (
          !fileObject["file"].name.includes("csv") &&
          !fileObject["file"].name.includes("CSV") &&
          !fileObject["file"].name.includes("txt") &&
          !fileObject["file"].name.includes("TXT") &&
          !fileObject["file"].name.includes("xlsx") &&
          !fileObject["file"].name.includes("XLSX") &&
          !fileObject["file"].name.includes("xls") &&
          !fileObject["file"].name.includes("XLS") &&
          !fileObject["file"].name.includes("pdf") &&
          !fileObject["file"].name.includes("PDF") &&
          !fileObject["file"].name.includes("doc") &&
          !fileObject["file"].name.includes("DOC") &&
          !fileObject["file"].name.includes("docx") &&
          !fileObject["file"].name.includes("DOCX") &&
          !fileObject["file"].name.includes("png") &&
          !fileObject["file"].name.includes("PNG") &&
          !fileObject["file"].name.includes("jpg") &&
          !fileObject["file"].name.includes("JPG") &&
          !fileObject["file"].name.includes("xml") &&
          !fileObject["file"].name.includes("XML")
        ) {
          fileObject["fileExtensionError"] =
            "The file uploaded is not supported.";
          hasFileExtensionErrors = true;
        } else {
          fileObject["fileExtensionError"] = null;
        }
        fileObject["selectedFileType"] = 0;
        fileObjects.push(fileObject);
        fileCount++;
      }
    });

    this.setState({
      fileObjects: fileObjects,
      disableUpload: hasFileExtensionErrors,
    });
  }

  async uploadFiles() {
    if (this.state.fileObjects.length === 0) {
      return;
    }

    const selectedFileType = this.state.fileObjects.filter(
      (file) => file.selectedFileType === 0
    );

    if (selectedFileType.length !== 0) {
      return;
    }

    for (let i = 0; i < this.state.fileObjects.length; i++) {
      let fileobject = this.state.fileObjects[i];
      if (
        fileobject["file_type_label"] === "other" &&
        (fileobject["other_description"] === undefined ||
          fileobject["other_description"] === null ||
          fileobject["other_description"] === "")
      ) {
        this.state.fileObjects[i]["fileExtensionError"] =
          "Please provide other description.";
        this.setState({
          fileObjects: this.state.fileObjects,
        });
        return;
      }
    }

    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.fileObjects.forEach((fileObject, index) => {
      promises.push(
        this.sendRequest(
          fileObject["file"],
          fileObject["file_type"],
          fileObject["other_description"],
          index
        )
      );
    });
    try {
      const response = await Promise.all(promises);
      let json_response = JSON.parse(response);
      if (
        json_response.success === false &&
        json_response.errors &&
        json_response.errors.length > 0
      ) {
        //toast.error(json_response.errors[0].error_message);
        this.setState({ successfullUploaded: false, uploading: false });
        return;
      }
      if (typeof json_response === "string") {
        this.setState({ successfullUploaded: false, uploading: false });
        return;
      }
      this.setState({ successfullUploaded: true, uploading: true });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: false, uploading: false });
    }
  }

  sendRequest(file, fileType, otherDescription, index) {
    const self = this;
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = { ...self.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
            response: null,
          };
          self.setState({ uploadProgress: copy });
        }
      });

      req.onload = () => {
        const copy = { ...self.state.uploadProgress };
        copy[file.name] = {
          state: "done",
          percentage: 100,
          response: req.response,
        }; // TODO: revisit removed JSON.parse(req.response)
        self.setState({ uploadProgress: copy });
        resolve(req.response);
      };

      req.upload.addEventListener("error", () => {
        const copy = { ...self.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0, response: null };
        self.setState({ uploadProgress: copy });
        reject(req.response.json());
      });

      // Note: fileType here is the id for the file_entity_type
      // it is intended in future to be the value that holds whether or not
      // it is a configuration file (vs a customer file)
      // on the back end this type integer is used
      // to get the file_entity_type currently.
      const formData = new FormData();
      formData.append("files", file);
      formData.append(
        "file_data",
        JSON.stringify({
          filename: file.name,
          file_type: fileType,
          other_description: otherDescription,
        })
      );
      req.open("POST", EnvConfig.getConfig().apiUrl + "/api/upload-file/");
      req.setRequestHeader("Authorization", "Bearer " + cookies.get("token"));
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          let json_response = JSON.parse(req.response);
          if (
            json_response.success === false &&
            json_response.errors &&
            json_response.errors.length > 0
          ) {
            const { fileObjects } = self.state;
            fileObjects[index].errors = json_response.errors;
            self.setState({ fileObjects });
            return false;
          }

          self.setState({ isFileUplodSuccess: true });
        }
      };
      req.send(formData);
    });
  }

  renderActions(classes) {
    if (this.state.successfullUploaded || this.state.disableUpload) {
      return (
        <Button
          variant="contained"
          onClick={() =>
            this.setState({
              fileObjects: [],
              successfullUploaded: false,
              disableUpload: false,
            })
          }
          endIcon={<ArrowForward />}
          className={classes.btnUpload}
        >
          Clear
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          onClick={this.uploadFiles}
          disabled={this.state.disableUpload}
          endIcon={<ArrowForward />}
          className={classes.btnUpload}
        >
          Upload
        </Button>
      );
    }
  }

  handleSelectMapping = (optionSelected, fileObject) => {
    let newFileObjects = this.state.fileObjects.slice();
    newFileObjects.forEach((newFileObject) => {
      if (newFileObject["file"].name === fileObject["file"].name) {
        const [file_type, file_type_label] = optionSelected.split("_");
        newFileObject["file_type"] = file_type;
        newFileObject["file_type_label"] = file_type_label;
        newFileObject["selectedFileType"] = optionSelected;
      }
    });

    this.setState({
      fileObjects: newFileObjects,
      // selectedFileType: optionSelected,
    });
  };

  handleOtherTextMapping = (value, fileObject) => {
    let newFileObjects = this.state.fileObjects.slice();
    newFileObjects.forEach((newFileObject) => {
      if (newFileObject["file"].name === fileObject["file"].name) {
        newFileObject["other_description"] = value;
      }
    });
    this.setState({
      fileObjects: newFileObjects,
    });
  };

  handleDeleteFile = (_e, fileObject) => {
    let files = [...this.state.fileObjects];
    let index = files.findIndex((f) => fileObject.file.name === f.file.name);
    files.splice(index, 1);
    let hasErrors = false;
    files.forEach((file) => {
      if (file["fileExtensionError"] !== null) {
        hasErrors = true;
      }
    });
    this.setState({ fileObjects: files, disableUpload: hasErrors });
  };

  renderProgressOrUpload({ placeholder }) {
    if (this.state.uploading || this.state.successfullUploaded) {
      const uploadProgress = this.state.uploadProgress[
        this.state.fileObjects[0]["file"].name
      ];
      const progress = uploadProgress ? uploadProgress.percentage : 0;
      return (
        <React.Fragment>
          <LinearWithValueLabel load={progress}></LinearWithValueLabel>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Dropzone
            placeholder={placeholder}
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullUploaded}
          />
        </React.Fragment>
      );
    }
  }

  onUploadFileNameChange = (event) => {
    const { value } = event.target;
    if (!value || this.state.fileObjects.length === 0) return;
    console.log(this.state.fileObjects);
    let fileObjects = [...this.state.fileObjects];
    const myNewFile = new File([this.state.fileObjects[0]["file"]], value, {
      type: this.state.fileObjects[0]["file"].type,
    });
    console.log(myNewFile);
    fileObjects[0]["file"] = myNewFile;
    this.setState({ fileObjects });
  };

  resetUploader() {
    this.setState({
      fileObjects: [],
      maxFilesAllowed: 10,
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      fileType: this.props.entityType,
      entityType: {},
      disableUpload: false,
      fileId: null,
      reportId: this.props.reportId,
      reportData: { report_type: {} },
      mappings: [],
      //selectedFileType: 0,
      previouslySelected: this.props.selected,
      progress: 0,
      showProgresBar: false,
      isFileUplodSuccess: false,
    });
  }

  render() {
    const { classes } = this.props;
    const appHeader = `${EnvConfig.getConfig().appHeader} Upload`;
    const { isFileUplodSuccess } = this.state;
    if (isFileUplodSuccess) {
      return <Redirect to="/FileUpload/Success?status=true" />;
    }

    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{appHeader}</title>
        </Helmet>
        <React.Fragment>
          <div className="Content">
            <Grid container>
              <Grid item xs={12}>
                <UploadFileHeader title="Upload Files"></UploadFileHeader>
              </Grid>
              <Grid item xs={12}>
                {this.renderProgressOrUpload({
                  placeholder: `Drag and drop file(s) here`,
                })}
              </Grid>

              {this.state.fileObjects.map((fileObject) => (
                <Grid item xs={12}>
                  <Mapping
                    fileObject={fileObject}
                    // mappingId={selectedFileType}
                    handleSelectMapping={this.handleSelectMapping}
                    mappings={this.props.customerFileTypes}
                    handleDeleteFile={this.handleDeleteFile}
                    handleUploadFileNameChange={this.onUploadFileNameChange}
                    handleOtherTextMapping={this.handleOtherTextMapping}
                  ></Mapping>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="Actions">{this.renderActions(classes)}</div>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.UploadFileReducer;
};

const mapDispatchToProps = (dispatch) => ({
  getCustomerFileTypes: () => dispatch(GetCustomerFileType()),
});
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Upload)
);
