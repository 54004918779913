import devConfigs from './dev_env'
import localConfigs from './local_env'
import demoConfigs from './demo_env'
import prodConfigs from './prod_env'

class EnvConfig {

    constructor() {
        if(process.env.REACT_APP_ENV === "development"){
            this.config = devConfigs;
        }
        else if(process.env.REACT_APP_ENV === "demo"){
            this.config = demoConfigs;
        }
        else if(process.env.REACT_APP_ENV === "production"){
            this.config = prodConfigs;
        }
        else if(process.env.REACT_APP_ENV === "local"){
            this.config = localConfigs;
        }
        else{
            this.config = devConfigs;
        }
    }

    static getConfig() {
        if(process.env.REACT_APP_ENV === "development"){
            return devConfigs;
        }
        else if(process.env.REACT_APP_ENV === "demo"){
            return demoConfigs;
        }
        else if(process.env.REACT_APP_ENV === "production"){
            return prodConfigs;
        }
        else if(process.env.REACT_APP_ENV === "local"){
            return localConfigs;
        }
        else{
            return devConfigs;
        }
    }
}

export default EnvConfig;