import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LinearProgress, Grid } from "@material-ui/core";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        {CircularProgressWithLabel(props)}
        <LinearProgress variant="determinate" {...props}></LinearProgress>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={10}>
        <Box
          position="relative"
          zIndex="100"
          display="inline-flex"
          top={95}
          left={25}
          right={50}
          style={{ width: "92%" }}
        >
          <Box
            style={{
              backgroundColor: "#000000",
              width: "48px",
              height: "48px",
              borderRadius: "24px",
            }}
          >
            <CircularProgress
              style={{
                color: "#FFFFFF",
                position: "relative",
                top: "15px",
                borderRadius: "25px",
                backgroundColor: "#000000",
                opacity: "0.5",
              }}
              size="20px"
              thickness={7}
            />
          </Box>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingLeft={2}
          >
            <Typography
              style={{ color: "#FFFFFF" }}
              variant="caption"
              component="div"
            >{`Uploading ${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        {/*  <Box
          position="relative"
          zIndex="100"
          display="inline-flex"
          top={95}
          left={0}
          right={15}
          style={{
            backgroundColor: "#f0f0f0",
            width: "48px",
            height: "48px",
            borderRadius: "24px",
          }}
        >
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingLeft={2.5}
            onClick={() => handelOnUploadCancel()}
            style={{ cursor: "pointer" }}
          >
            <Typography
              style={{ color: "#000000", fontWeight: "bold" }}
              variant="caption"
              component="div"
            >
              X
            </Typography>
          </Box>
        </Box> */}
      </Grid>
    </Grid>
  );
}

const styles = () => ({
  root: {
    height: "162px",
    backgroundColor: "#f7f7f7",
  },
  barColorPrimary: {
    backgroundColor: "#312e3a",
  },
});

const LinearWithValueLabel = (props) => {
  const { classes } = props;
  return (
    <div>
      <div>
        <LinearProgressWithLabel
          className={classes}
          value={props.load}
          {...props}
        />
      </div>
    </div>
  );
};
export default withStyles(styles)(LinearWithValueLabel);
