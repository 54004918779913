import React from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  const redirectDefault = (props) => {
    props.history.push("/login");
  };

  const {
    location: { search },
  } = rest;
  const isAuth0 = search.includes("code=");

  return (
    <Route {...rest}>
      {(props) =>
        isAuthenticated || isLoading ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          !isAuth0 && redirectDefault(props)
        )
      }
    </Route>
  );
};

export default PrivateRoute;
