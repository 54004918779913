import {
  Avatar,
  FormControl,
  Grid,
  InputLabel,
  withStyles,
  Typography,
  TextField,
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 12,
    color: "red",
  },
  avtarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: "#f0f0f0",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    height: "22px",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "normal",
    color: "#000000",
  },
});
class SelectMapping extends Component {
  state = {};

  render() {
    const {
      classes,
      fileObject,
      // mappingId,
      handleSelectMapping,
      mappings,
      handleDeleteFile,
      handleOtherTextMapping,
    } = this.props;
    return (
      <div className={classes.root}>
        <hr></hr>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <table width="100%">
              <tr>
                <td align="left" style={{ paddingTop: "15px" }}>
                  <Avatar className={classes.avtarSmall}>
                    <FolderIcon style={{ color: "black" }}></FolderIcon>
                  </Avatar>
                </td>
                <td align="left" style={{ paddingTop: "15px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Typography>{fileObject["file"].name || ""}</Typography>
                  </span>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl}>
              <InputLabel id="file-type-select">Select file type</InputLabel>
              <Select
                id="file-type-select-label"
                className={classes.selectEmpty}
                style={{
                  width: "260px",
                  height: "30px",
                }}
                value={fileObject["selectedFileType"] || 0}
                onChange={(e) =>
                  handleSelectMapping(e.target.value, fileObject)
                }
              >
                <MenuItem value="" disabled>
                  Select file type
                </MenuItem>
                {mappings.map((mapping) => {
                  return (
                    <MenuItem
                      key={mapping.id}
                      value={`${mapping.id}_${mapping.file_type}`}
                      className={classes.menuItem}
                    >
                      {mapping.file_type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {fileObject["file_type_label"] === "other" && (
              <TextField
                id="standard-basic"
                label="Other description"
                style={{ width: "250px", paddingTop: "5px" }}
                inputProps={{
                  maxLength: 500,
                }}
                onChange={(e) =>
                  handleOtherTextMapping(e.target.value, fileObject)
                }
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <table width="100%">
              <tr>
                <td align="right" style={{ paddingTop: "10px" }}>
                  <Avatar className={classes.avtarSmall}>
                    <HighlightOffIcon
                      style={{ color: "black" }}
                      onClick={(e) =>
                        handleDeleteFile(e.target.value, fileObject)
                      }
                    />
                  </Avatar>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} sm={12}>
            {(fileObject?.errors || []).map((error, idx) => (
              <Typography key={idx} value={idx} style={{ color: "red" }}>
                {error}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            {fileObject["fileExtensionError"] && (
              <Typography style={{ color: "red" }}>
                {fileObject["fileExtensionError"]}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SelectMapping);
