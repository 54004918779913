import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import API from "../../utility/axiosClient";
import { Helmet } from "react-helmet";
import EnvConfig from "../../config/config";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Axios from "axios";
import cookies from "../../utility/cookies";
import { Delete } from "@material-ui/icons";
import { ConfirmContainer } from "../../common/ConfirmContainer";

const fileStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "28px",
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
  },
  table: {
    overflow: "hidden",
    width: "95%",
    margin: "1.5% 2.5%",
    overflowX: "auto",
    backgroundColor: "#FFF",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  tableCell: {
    fontSize: 16,
    fontWeight: 600,
    color: "#fff",
  },
  textField: {
    width: "300px",
  },
  btnStyle: {
    marginTop: theme.spacing(2),
    backgroundColor: "#312e3a",
    color: "#fff",
    justifyContent: "inherit",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
});

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      entityTypes: [],
      mappings: [],
      selectedFileType: "",
      rowsPerPage: 10,
      addFile: false,
      error: true,
      count: 0,
      page: 0,
      showFilter: false,
      selectedFilterFileEntityType: null,
      file: null,
    };
    // this.setSort = this.setSort.bind(this);

    // this.setPage = this.setPage.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    // this.setRowsPerPage = this.setRowsPerPage.bind(this);
  }

  // setRowsPerPage = (rowsPerPage) => {
  //   this.setState({ rowsPerPage }, () => {
  //     this.getFiles();
  //   });
  // };

  // setPage = (page) => {
  //   this.setState({ page }, () => {
  //     this.getFiles();
  //   });
  // };

  async onDeleteFile(action) {
    const { file } = this.state;
    try {
      if (action) {
        await API.delete(`api/customer-files/${file.id}/`);
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ file: null }, this.getFiles);
  }

  async componentDidMount() {
    this.getFiles();
    // const entityTypesData = await API.get(`/api/file-entity-types/`);
    // const mappingsData = await API.get(`/api/customer-file-mappings/`);
    // this.setState({
    //   entityTypes: entityTypesData.data.results,
    //   mappings: mappingsData.data.results,
    // });
  }

  // handleAddFileClick = (event) =>
  //   this.setState({ addFile: event.currentTarget });

  // handleAddFileClose = () => this.setState({ addFile: null });

  // handleFileTypeChange = (e) =>
  //   this.setState({ selectedFileType: e.target.value });

  // handleUploadFileClick = () => {
  //   if (this.state.selectedFileType) {
  //     window.location = "/uploader?entity_type=" + this.state.selectedFileType;
  //   } else {
  //     return;
  //   }
  // };

  getFiles = async () => {
    this.setState({ files: [] });
    const {
      data: { results, count },
    } = await API.get(`/api/customer-files/`);
    this.setState({
      files: results,
      count: count,
    });
  };

  // handleChangePage = (event, page) =>
  //   this.setState({ page }, () => {
  //     this.getFiles();
  //   });

  // handleChangeRowsPerPage = (event) =>
  //   this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
  //     this.getFiles();
  //   });

  // setSort(property) {
  //   const { sort } = this.state;
  //   sort.isAsc = sort.key === property.sortKey ? !sort.isAsc : true;
  //   sort.key = property.sortKey;
  //   sort.column = property.column || property.sortKey;
  //   this.setState({ sort });
  //   this.updateSort();
  // }

  // updateSort() {
  //   const { files, sort } = this.state;
  //   let _files = sortBy(files, (file) =>
  //     (get(file, sort.column) || "").toLowerCase()
  //   );

  //   if (!sort.isAsc) _files = _files.reverse();
  //   this.setState({ files: _files });
  // }

  // handelOnFilterClick = () => {
  //   this.setState({ showFilter: !this.state.showFilter });
  // };

  // saveFilter = ({ selectedFileEntityType }) => {
  //   this.setState({ selectedFilterFileEntityType: selectedFileEntityType });
  //   this.setState({ showFilter: !this.state.showFilter });
  //   this.getFiles(selectedFileEntityType);
  // };

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page, count, file } = this.state;
    const appHeader = `${EnvConfig.getConfig().appHeader}  Reports`;
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{appHeader}</title>
        </Helmet>
        <Grid
          container
          spacing={0}
          style={{ marginBottom: "14px", minHeight: "140px" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.title}>File Management</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FileManagementGrid
            files={this.state.files}
            history={this.props.history}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={this.setPage}
            deleteFile={(file) => this.setState({ file })}
            setRowsPerPage={this.setRowsPerPage}
          />
          {file && (
            <ConfirmContainer
              open={true}
              title={"Delete?"}
              description={`would you like to delete file "${file.file_name}"?`}
              confirmTxt={"OK"}
              onAction={this.onDeleteFile}
            />
          )}
        </Grid>
      </div>
    );
  }
}

const FileManagementGrid = ({ files, deleteFile }) => {
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const downloadFile = async (file) => {
    Axios.defaults.headers.common = {
      Authorization: "Bearer " + cookies.get("token"),
    };
    Axios({
      url: EnvConfig.getConfig().apiUrl + `/api/download-file/${file.id}/`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${file.file_name.replace(/\.[^.]*$/, "")}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((er) => er);
  };

  // const deleteFile=(file)=>{

  // }

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead style={{ backgroundColor: "#332D3C" }}>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                File
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                File Type
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Description
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Created Date
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Last updated
              </TableCell>
              <TableCell
                align="center"
                style={{ fontSize: 16, fontWeight: 600, color: "#fff" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <TableRow key={file.id}>
                <TableCell align="center">{file.file_name}</TableCell>
                <TableCell align="center">
                  {file.customer_file_type?.file_type}
                </TableCell>
                <TableCell align="center">
                  {file?.customer_file_type?.file_type === "other"
                    ? file?.other_description
                    : file?.customer_file_type?.description}
                </TableCell>
                <TableCell align="center">{file.created_at}</TableCell>
                <TableCell align="center">{file.updated_at}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() => downloadFile(file)}
                      style={{
                        minWidth: "26px",
                        padding: "7px",
                        marginRight: 10,
                      }}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => deleteFile(file)}
                      style={{
                        minWidth: "26px",
                        padding: "7px",
                        marginRight: 10,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {/* <TableFooter>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    style: {
                      minWidth: "26px",
                      padding: "7px",
                      marginRight: "10px",
                    },
                  }}
                  nextIconButtonProps={{
                    style: { minWidth: "26px", padding: "7px" },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(fileStyles)(Files);
